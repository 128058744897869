import React, { FormEvent, useEffect, useMemo, useState } from 'react'
import { Col, Row } from 'react-grid-system'
import { Alert, Button, Modal } from '../common'
import * as modalStyles from './reusableModals.module.scss'
import { ImportModalProps } from './types'

export const ImportModal = ({
   variant,
   isModalOpen,
   handleCloseImportModal,
   handleSuccessfulImport,
   importSelectedFile,
   isImportingData,
   importError,
   importFileErrors,
   isImportingSuccess,
}: ImportModalProps) => {
   const [importFile, setImportFile] = useState<File | null>(null)
   const [errorMessage, setErrorMessage] = useState<string | null>(null)
   const [fileErrorMessages, setFileErrorMessages] = useState<string[]>([])

   useEffect(() => {
      if (isImportingSuccess) {
         handleSuccessfulImport()
         handleCloseModal()
      }
   }, [isImportingSuccess])

   useEffect(() => {
      if (importError) {
         setErrorMessage(importError)
      }
   }, [importError])

   useEffect(() => {
      if (importFileErrors) {
         setFileErrorMessages(importFileErrors)
      }
   }, [importFileErrors])

   const handleImportFormSubmit = (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      importSelectedFile(importFile)
   }

   const handleCloseModal = () => {
      setImportFile(null)
      setErrorMessage(null)
      setFileErrorMessages([])
      handleCloseImportModal()
   }

   const title = useMemo(() => `Import ${variant}`, [variant])

   const templateHref = useMemo(
      () => `/${variant}ImportTemplate.csv`,
      [variant]
   )

   const templateDownload = useMemo(() => `${variant}ImportTemplate`, [variant])

   const templateLabel = useMemo(() => `${variant} Import template`, [variant])

   return (
      <Modal
         isModalVisible={isModalOpen}
         closeModal={handleCloseModal}
         title={title}
      >
         <form
            className={modalStyles.importForm}
            onSubmit={handleImportFormSubmit}
            noValidate
         >
            <p>Select your saved .csv file here</p>

            <input
               type="file"
               name="import"
               id="import"
               onChange={(event) =>
                  setImportFile(event.target.files[0] ?? null)
               }
               accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
               multiple={false}
            />

            <hr />

            <p>Download an import template here</p>
            <p className={modalStyles.importDownloadExample}>
               <a href={templateHref} download={templateDownload}>
                  {templateLabel}
               </a>
            </p>

            {errorMessage && <Alert message={errorMessage} />}
            {fileErrorMessages && fileErrorMessages?.length > 0 && (
               <div className={modalStyles.importErrorWrapper}>
                  <ol className={modalStyles.importErrorList}>
                     {fileErrorMessages.map((error, index) => (
                        <li key={`${error}_${index}`}>{error}</li>
                     ))}
                  </ol>
               </div>
            )}

            <Row gutterWidth={20}>
               <Col xs={6}>
                  <Button
                     minWidth="100%"
                     disabled={!importFile}
                     loading={isImportingData}
                  >
                     Import
                  </Button>
               </Col>
               <Col xs={6}>
                  <Button
                     minWidth="100%"
                     variant="tertiary"
                     onClick={handleCloseModal}
                     isReset
                  >
                     Cancel
                  </Button>
               </Col>
            </Row>
         </form>
      </Modal>
   )
}
