export const FILTER_LABELS = {
    barcode: 'Barcode',
    completedEndDate: 'Completed to',
    completedStartDate: 'Completed before',
    dueEndDate: 'Due before',
    dueStartDate: 'Due from',
    endDate: 'End date',
    onlyToolsMarkedAsRental: 'Tools marked as rental only',
    selectedConnections: 'Connections',
    selectedItems: 'Items',
    selectedStatus: 'Status',
    sentStartDate: 'Start date',
    sentEndDate: 'End date',
    hideInactive: 'Hide inactive',
    includeArchived: 'Include archived',
    serial: 'Serial number',
    startDate: 'Start date',
    toolStatus: 'Status',
    type: 'Type',
    completedBy: 'Completed by',
    firstName: 'First name',
    lastName: 'Last name',
    emailAddress: 'Email address',
    location: 'Location',
    gpsStartDate: 'GPS Start Date',
    gpsEndDate: 'GPS End Date',
}

export const PREFIX_FILTER_NAME = {
    lastScannedBy: 'lastScannedBy',
    lastPingedBy: 'lastPingedBy',
}