import React from 'react';
import { format } from 'date-fns';

import { Tag } from '../..';
import { FILTER_LABELS, PREFIX_FILTER_NAME } from '../constants';
import { FORMATS } from '../../../../constants/datetime';

import { Props } from './filterTags.type';
import * as styles from './filterTags.module.scss';

// check for prefix if needed otherwise will just display person names
const addPrefix = (name: string) => {
    let prefix = '';
    switch(name) {
        case PREFIX_FILTER_NAME.lastScannedBy:
            prefix = 'Last Scanned By:';
        return prefix;
        case PREFIX_FILTER_NAME.lastPingedBy:
            prefix = 'Last Pinged By:'
        return prefix;
        default: 
            return '';
    }
}

const FilterTags: React.FC<Props> = ({ exclude = [], filters, onClick }) => {
    if (!Object.entries(filters).length) {
        return null;
    }

    const renderTag = ([name, filter]: [string, any]) => {
        if (exclude.includes(name)) {
            return null;
        }

        // if it's an array, it's a dropdown field
        if (Array.isArray(filter?.value)) {
            return filter?.value?.map(option => (
                <li key={`${name}-${option?.value}`} className={styles.listItem}>
                    <Tag
                        label={`${addPrefix(name)} ${option.label}`}
                        onClick={() => onClick(name, option.value)}
                        title={option?.label}
                    />
                </li>
            ));
        }

        // if it's a date, it's a datepicker field
        if (filter?.value instanceof Date) {
            return (
                <li key={name} className={styles.listItem}>
                    <Tag
                        label={`${FILTER_LABELS[name]}: ${format(filter?.value, FORMATS.filterDateUI)}`}
                        onClick={() => onClick(name, filter.value)}
                        title={filter?.label}
                    />
                </li>
            );
        }

        // if it's a boolean, it's a toggle field
        if (typeof filter?.value === 'boolean') {
            return (
                <li key={name} className={styles.listItem}>
                    <Tag
                        label={`${FILTER_LABELS[name]}`}
                        onClick={() => onClick(name, filter.value)}
                        title={filter?.label}
                    />
                </li>
            );
        }

        // otherwise, it's a straight forward key:value filter
        return (
            <li key={name} className={styles.listItem}>
                <Tag
                    label={`${FILTER_LABELS[name]}: ${filter.value}`}
                    onClick={() => onClick(name, filter.value)}
                    title={filter?.label}
                />
            </li>
        );
    };

    return (
        <ul className={styles.list}>
            {Object.entries(filters).map(renderTag)}
        </ul>
    );
};

export default FilterTags;